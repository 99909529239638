import { PolymerElement } from '@polymer/polymer/polymer-element.js';
import {classlist} from "caniuse-lite/data/features";
class DbqDropdown extends PolymerElement {
  static get is() {
    return 'dbq-dropdown';
  }

  static get properties() {
    return {
      active: {
        type: Boolean,
        reflectToAttribute: true
      },
      // ariaExpanded: {
      //   type: String,
      //   value: 'false',
      // },
      // tabIndex: {
      //   type: String,
      //   value: '-1'
      // },
      // visibility: {
      //   type: String,
      //   value: 'hidden'
      // }
    };
  }

  // Property tabIndex is temporarily removed because it
  // added the ATTRIBUTE to the <dbq-dropdown> tag,
  // not the .dropMenu classname, where it should be.

  // Property visibility is removed because it is
  // now being handled in the various CCSS file(s).

  // I left the code in place, but commented out, in case
  // it is needed in the future. OR for review.

  //ariaExpanded is also removed because it is needed on the .dropTitle

  constructor() {
    super();
  }

  ready() {
    super.ready();
    setTimeout(() => {
      this.addEventListener('click', (event) => {
        let classList = event.target.classList;
        let tagName = event.target.tagName;
        // console.log("🚀 ~ file: dbq-dropdown.js ~ line 26 ~ DbqDropdown ~ this.addEventListener ~ tagName", tagName)
        if (
          classList.contains('dropMenu') ||
          classList.contains('dropItem') ||
          tagName === 'DBQ-DROPDOWN'
        ) {
          return;
        }
        this.toggle();
      });
    });
    document.body.addEventListener('click', (event) => {
      let classList = event.target.classList;
      let tagName = event.target.tagName;
      if (
        classList.contains('dropTitle') ||
        classList.contains('dropMenu') ||
        classList.contains('dropItem') ||
        classList.contains('icon') ||
        classList.contains('icon-text') ||
        classList.contains('icon-white') ||
        tagName === 'SPAN' ||
        tagName === 'DIV' ||
        tagName === 'I'
      ) {
        return;
      }
      this.active = false;
      // this.ariaExpanded = 'false';
      this.querySelector('.dropTitle').ariaExpanded = 'false';
      // this.tabIndex = '-1';
      // this.querySelector('.dropMenu').tabIndex = '-1';
      // this.querySelector('.dropMenu').style.visibility = 'hidden';
    });
  }

  toggle() {
    if (!this.active) {
      document.querySelectorAll('dbq-dropdown[active]').forEach((el) => {
        el.active = false;
        // el.ariaExpanded = 'false';
        this.querySelector('.dropTitle').ariaExpanded = 'false';
        // el.tabIndex = '-1';
        // this.querySelector('.dropMenu').tabIndex = '-1';
        // this.querySelector('.dropMenu').style.visibility = 'hidden';
      });
    }
    this.active = !this.active;
    this.querySelector('.dropTitle').ariaExpanded = this.active ? 'true' : 'false';
    // this.tabIndex = this.active ? '0' : '-1';
    // this.querySelector('.dropMenu').tabIndex = this.active ? '0' : '-1';
    // this.querySelector('.dropMenu').style.visibility = this.active ? 'visible' : 'hidden';
  }

  close(){
    this.active = false;
    this.querySelector('.dropTitle').ariaExpanded = 'false';
    // this.tabIndex = '-1';
    // this.querySelector('.dropMenu').tabIndex = '-1';
    // this.querySelector('.dropMenu').style.visibility = 'hidden';
  }
}
customElements.define(DbqDropdown.is, DbqDropdown);
